class FiltersActionsStore {
  constructor({ filtersStore, contentStore }) {
    this.filtersStore = filtersStore
    this.contentStore = contentStore
  }

  applyFilters = () => {
    this.contentStore.fetchCollection(this.filtersStore.filters)
  }

  applyFiltersWithFetchingIndicator = () => {
    this.contentStore.setFetching(true)
    this.contentStore.fetchCollection(this.filtersStore.filters)
  }

  onPageChange = value => {
    this.filtersStore.updatePage(value)
    this.applyFilters()
  }

  onKeywordChange = value => {
    this.filtersStore.updateKeyword(value)
  }

  resetFilter = () => {
    this.filtersStore.resetData()
    this.applyFilters()
  }
}

export default FiltersActionsStore
