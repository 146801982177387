/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { action, observable, computed, toJS, makeObservable } from 'mobx'
import { isEmpty, sanitizedParams } from '../../../lib/utils/collection'
import { noop } from '../../../lib/utils/common'
import { SHARE_EMAIL, SIGNIN_EMAIL, CONTACT_EMAIL, CONTACT_NAME, PHONE_NUMBER } from '../../../lib/services/userInfo'

class UserStore {
  @observable email = ''
  @observable password = ''
  @observable phone = ''
  @observable name = ''
  @observable responseResetPassword = false
  @observable currentPassword = ''
  @observable passwordConfirmation = ''
  @observable resetPasswordToken = ''
  @observable errors = {}

  constructor({ transport, location, sessionStorage, userInfo, gaEvents}) {
    makeObservable(this)

    this.transport = transport
    this.location = location
    this.userInfo = userInfo
    this.gaEvents = gaEvents
    this.sessionStorage = sessionStorage
    this.resetAfterAuthenticatedPath()
  }

  @action('[UserStore] set initial data')
  setInitialData() {
    this.email = this.userInfo.getInfo(SIGNIN_EMAIL)
      || this.userInfo.getInfo(CONTACT_EMAIL)
      || this.userInfo.getInfo(SHARE_EMAIL)
      || this.email
    this.name = this.userInfo.getInfo(CONTACT_NAME)
    this.phone = this.userInfo.getInfo(PHONE_NUMBER)
  }

  @computed
  get toForm() {
    return {
      email: this.email,
      password: this.password,
      currentPassword: this.currentPassword,
      passwordConfirmation: this.passwordConfirmation
    }
  }

  @computed
  get formErrors() {
    return toJS(this.errors)
  }

  @action('[UserStore] set field')
  setField = (name, value) => {
    this[name] = value
    this.resetErrors()
  }

  @action('[UserStore] setErrors')
  setErrors = v => {
    this.errors = v
  }

  @action('[UserStore] setServerErrors')
  setServerErrors = errors => {
    this.errors = {
      ...this.errors,
      ...(errors && { unknownUser: errors })
    }
  }

  isFieldInvalid = fieldName => {
    return fieldName in this.formErrors
  }

  @action('[UserStore] reset password')
  resetPassword = v => {
    this.responseResetPassword = v
  }

  @action('[UserStore] reset errors')
  resetErrors = () => {
    this.errors = {}
  }

  handleEditPassword = () => {
    const data = {
      user: {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        resetPasswordToken: this.location.getParam('reset_password_token')
      }
    }

    this.transport.User.editPassword(data).then(resp => {
      if (resp && resp.errors) {
        const { password, passwordConfirmation, ...tokenErrors } = resp.errors
        const errors = sanitizedParams({ password, passwordConfirmation })

        this.setErrors(errors)

        if (!isEmpty(tokenErrors)) {
          this.setServerErrors('Reset password link is no longer valid')
        }
      } else {
        this.location.redirect('/')
      }
    })
  }

  handleSetPassword = () => {
    const data = {
      user: {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        invitationToken: this.location.getParam('invitation_token')
      }
    }

    this.transport.User.setPassword(data).then(resp => {
      if (resp && resp.errors) {
        const { password, passwordConfirmation, ...tokenErrors } = resp.errors
        const errors = sanitizedParams({ password, passwordConfirmation })

        this.setErrors(errors)

        if (!isEmpty(tokenErrors)) {
          this.setServerErrors('Reset password link is no longer valid')
        }
      } else {
        this.location.redirect('/')
      }
    })
  }


  handleLogin = (additionalData = {}) => {
    const userInfo = {
      ...additionalData,
      user: {
        email: this.email,
        password: this.password
      }
    }

    return this.transport.User.signIn(userInfo).then(resp => {
      if (resp.error) {
        this.setServerErrors(resp.error)
      } else {
        this.sendAnalytics({ event: 'login' })
        this.userInfo.setInfo(SIGNIN_EMAIL, this.email)
        this.afterAuthenticate(resp)
      }
    }).catch(noop)
  }

  handleRegister = (additionalData = {}) => {
    const userRegistration = {
      ...additionalData,
      user: {
        email: this.email,
        password: this.password,
        name: this.name,
        phone: this.phone
      }
    }

    return this.transport.User.signUp(userRegistration).then(action(resp => {
      if (resp.errors) {
        const { email, password, ...serverErrors} = resp.errors
        const errors = sanitizedParams({ email, password })

        this.setErrors(errors)
        this.setServerErrors(Object.values(serverErrors).join(','))
      } else {
        this.sendAnalytics({ event: 'register' })
        this.userInfo.setInfo(SIGNIN_EMAIL, this.email)
        this.afterAuthenticate(resp)
      }
    })).catch(noop)
  }

  handleResetPassword = (additionalData = {}) => {
    const userResetPassword = {
      ...additionalData,
      user: {
        email: this.email
      }
    }

    return this.transport.User.resetPassword(userResetPassword).then(resp => {
      if (resp.error) {
        this.resetPassword(false)
        this.setServerErrors(resp.error)
      } else if (resp.errors) {
        const errors = sanitizedParams(resp.errors)

        this.setErrors(errors)
        this.resetPassword(false)
      } else {
        this.resetPassword(true)
      }
    })
  }

  sendAnalytics = ({ event }) => {
    this.gaEvents.UserProfile.userAuth(event)
  }

  afterAuthenticate = resp => {
    const path = this.sessionStorage.fetchFromSessionStorage('afterAuthenticatePath')

    if (resp.redirectURL) {
      this.location.redirect(resp.redirectURL)
    } else if (path) {
      this.resetAfterAuthenticatedPath()
      this.location.redirect(path)
    } else {
      this.location.reload()
    }
  }

  resetAfterAuthenticatedPath = () => {
    this.sessionStorage.saveToSessionStorage('afterAuthenticatePath', null)
  }
}

export default UserStore
