import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const divStyle = {
  position: 'relative'
}

const textProcessing = (text, length) => (
  text.substr(0, length)
)

const getTextOrHtmlSafe = (text, isHtmlSafe) => (
  isHtmlSafe ? <div dangerouslySetInnerHTML={{__html: text}}/> : text
)

const ViewMoreText = ({
  children,
  className,
  length = 1000,
  htmlSafe = false,
  buttonText = ' View More +'
}) => {

  const [isActive, setActive] = useState(children.length > length)

  const handleClickMore = () => {
    setActive(false)
  }
  return (
    <div style={divStyle}>
      {isActive ?
        getTextOrHtmlSafe(textProcessing(children, length), htmlSafe)
        :
        getTextOrHtmlSafe(children, htmlSafe)
      }
      <div className={cn('ToggableText', className, { isShown: isActive })} onClick={handleClickMore}>
        <div className='ToggableText-button'>
          <button
            className='Button Button--link Button--kbdOnlyFocus'
          >
            <div tabIndex='-1'>{buttonText}</div>
          </button>
        </div>
      </div>
    </div>
  )
}

ViewMoreText.propTypes = {
  children: PropTypes.string.isRequired,
  length: PropTypes.number,
  htmlSafe: PropTypes.bool,
  className: PropTypes.string,
  buttonText: PropTypes.string
}

export default ViewMoreText
