import { observable, action, computed, makeObservable } from 'mobx'

class CaptchaStore {
  @observable gRecaptchaResponse = ''
  @observable isCaptchaActive = false

  constructor({ captchaKey, transport }) {
    makeObservable(this)

    this.captchaKey = captchaKey
    this.transport = transport
  }

  @computed
  get isCaptchaValid() {
    return this.isCaptchaActive ? this.gRecaptchaResponse.length > 0 : !this.isCaptchaActive
  }

  @computed
  get toForm() {
    return {
      gRecaptchaResponse: this.gRecaptchaResponse
    }
  }

  @action('[CaptchaStore] update gRecaptchaResponse')
  updateRecaptchaResponse = value => {
    this.gRecaptchaResponse = value
  }

  @action('[CaptchaStore] reset gRecaptchaResponse')
  resetRecaptchaResponse = () => {
    this.gRecaptchaResponse = ''
  }

  @action('[CaptchaStore] update isCaptchaActive')
  setIsCaptchaActive = value => {
    this.isCaptchaActive = value
  }

  @action('[CaptchaStore] check shown captcha')
  checkCaptchaActive = () => {
    this.transport.Captcha.show(this.captchaKey)
      .then(resp => {
        this.setIsCaptchaActive(resp.success)
      })
  }
}

export default CaptchaStore
