import React from 'react'
import PropTypes from 'prop-types'
import { isPresent } from '../../../lib/utils/collection'
import Modal from '../../../components/Shared/Modal'
import MapView from '../MapView'
import MarkerClusterNearbyListings from '../MapView/MarkerClusterNearbyListings'

const MapModal = ({
  isMapModalOpen,
  deactivateModal,
  photoGalleryUIStateStore,
  mapStore,
  mapButtonRef
}) => (
  <Modal
    isOpen={isMapModalOpen}
    onRequestClose={deactivateModal}
    ariaHideApp={false}
    portalClassName='ReactModalPortal ReactModalPortal--videoTour'
    overlayClassName='Modal Modal--fullScreen'
  >
    <MapView
      className='MapView--lightBox'
      mapButtonRef={mapButtonRef}
      changeContentType={photoGalleryUIStateStore.changeContentType}
      mapStore={mapStore}
    >
      {isPresent(mapStore.nearbyListingsUrl) && <MarkerClusterNearbyListings mapStore={mapStore}/>}
    </MapView>
  </Modal>
)

MapModal.propTypes = {
  isMapModalOpen: PropTypes.bool.isRequired,
  photoGalleryUIStateStore: PropTypes.shape({
    changeContentType: PropTypes.func
  }),
  deactivateModal: PropTypes.func.isRequired,
  mapStore: PropTypes.object.isRequired,
  mapButtonRef: PropTypes.object
}

export default MapModal
