import { action, observable, makeObservable } from 'mobx'
import * as R from 'ramda'
import { fromServerToClient, sanitizedParams } from '../../../../lib/utils/collection'
import { withFolderServerMappings, withSelectablePricePeriod } from '../../../../lib/utils/stores'

const enhance = R.compose(withFolderServerMappings, withSelectablePricePeriod)

class ListingsPrintStore {
  @observable isPrintActive = false
  @observable collection = []
  @observable agent = {}

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  @action('[ListingsPrintStore] openPrintPreview')
  openPrintPreview = ({ selectedItems }) => {
    this.fetchInitialData(selectedItems)
    this.isPrintActive = true
  }

  @action('[ListingsPrintStore] closePrintPreview')
  closePrintPreview = () => {
    this.isPrintActive = false
    this.collection = []
    this.agent = {}
  }

  fetchInitialData = selectedItems => {
    const pricePeriods = selectedItems.filter(listing => listing.itemType === 'Rental').reduce((acc, val) => {
      return { ...acc, [val.item.id]: val.item.currentPricePeriod }
    }, {})

    const items = this.mapItemCollectionToServerValue(selectedItems)
    const data = { ...items, pricePeriods: sanitizedParams(pricePeriods) }

    this.transport.ManagePrintListings.get(data)
      .then(action(serverResponse => {
        const response = fromServerToClient(serverResponse)
        if (!response.error) {
          this.collection = [...response.sales, ...response.rentals, ...response.lands]
          this.agent = response.agent
        }
      }))
  }
}

export default enhance(ListingsPrintStore)
