import React from 'react'
import PropTypes from 'prop-types'

const MarkerLabel = ({ address, url, label }) => (
  <div className='MarkerLabel'>
    <div className='MarkerLabel-title'>{address} </div>
    {url && (
      <a className='MarkerLabel-link' href={url} target='_blank' rel='nofollow noopener'>
        {label}
      </a>
    )}
  </div>
)

MarkerLabel.propTypes = {
  address: PropTypes.string,
  url: PropTypes.string,
  label: PropTypes.string
}
export default MarkerLabel
