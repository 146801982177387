/* eslint-disable camelcase */
import { MarkerClusterTemplate } from '../../../components/Svg'
import { fixedEncodeURIComponent } from '../../../lib/utils/common'

const cssStyles = {
  height: 40,
  width: 40,
  textColor: '#5a5a5a',
  textSize: 18,
  fontFamily: 'P22Underground',
  fontWeight: 600
}

export default [
  {
    ...cssStyles,
    url: `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(MarkerClusterTemplate('#f4de51'))}`
  },
  {
    ...cssStyles,
    url: `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(MarkerClusterTemplate('#f35549'))}`,
    textColor: '#fff'
  },
  {
    ...cssStyles,
    url: `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(MarkerClusterTemplate('#eead5e'))}`
  },
  {
    ...cssStyles,
    url: `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(MarkerClusterTemplate('#d8f6e9'))}`
  },
  {
    ...cssStyles,
    url: `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(MarkerClusterTemplate('#7dd660'))}`
  },
  {
    ...cssStyles,
    url: `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(MarkerClusterTemplate('#3e807e'))}`
  }
]

export const markerColors = {
  beach: 1,
  transportation: 2,
  farm_and_food: 3,
  fitness: 4,
  recreation: 5,
  culture: 6
}


