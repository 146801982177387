import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { camelize } from 'humps'
import markerClusterStyles, { markerColors } from './markerClusterStyles'
import { MarkerClusterer, Marker, OverlayView } from '@react-google-maps/api'
import interestingPlacesPinUrls from './interestingPlacesPinUrls'

const MarkerClusterInterestingPlaces = ({ interestingPlaces, interestingPlacesFilters }) => {
  const createKey = location => location.lat + location.lng
  const createClusterKey = Object.values(interestingPlacesFilters).map(Number).join('')
  const filteredPlaces = interestingPlaces.filter(place => interestingPlacesFilters[camelize(place.placeType)])

  const clusterCalculator = markers => {
    const text = markers.length > 9 ? '9+' : markers.length
    const indexColors = markers.map(marker => markerColors[marker.icon.placeType])

    return { text: text, index: Math.min(...indexColors) }
  }

  const getPixelPositionOffset = (width, height) => {
    return {
      x: -(width / 2),
      y: height - 100
    }
  }

  const [activeMarkerIndex, setActiveMarkerIndex] = useState(null)

  const handleHideLabel = () => {
    setActiveMarkerIndex(null)
  }

  const handleShowLabel = index => () => {
    setActiveMarkerIndex(index)
  }

  return (
    <MarkerClusterer
      key={createClusterKey}
      averageCenter
      enableRetinaIcons
      zoomOnClick
      gridSize={40}
      maxZoom={17}
      clusterClass='MapView-markerCluster'
      styles={markerClusterStyles}
      calculator={clusterCalculator}
    >
      {clusterer => (
        filteredPlaces.map((place, index) => (
          <Marker
            key={createKey(place)}
            position={{ lat: +place.lat, lng: +place.lon }}
            clusterer={clusterer}
            icon={{
              url: interestingPlacesPinUrls[place.iconType],
              scaledSize: { width: 50, height: 65 },
              placeType: place.placeType
            }}
            onMouseOut={handleHideLabel}
            onMouseOver={handleShowLabel(index)}
          >
            {activeMarkerIndex === index &&
                <OverlayView
                  mapPaneName='markerLayer'
                  position={{ lat: +place.lat, lng: +place.lon }}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <span className='MapView-markerLabel'>{place.label}</span>
                </OverlayView>
            }
          </Marker>
        ))
      )}
    </MarkerClusterer>
  )
}

MarkerClusterInterestingPlaces.propTypes = {
  interestingPlaces: PropTypes.array.isRequired,
  interestingPlacesFilters: PropTypes.object.isRequired
}

export default observer(MarkerClusterInterestingPlaces)
