import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Checkbox = ({
  wrapperClassName,
  className,
  name,
  onChange,
  checked,
  label,
  labelClassName = '',
  additionalLabel,
  qaAdvancedByAgent,
  qaAdvancedByOwner,
  qaAdvancedOpenHouse
}) => (
  <div className={wrapperClassName}>
    <div className={cn('Checkbox', className)}>
      <label className={cn('Checkbox-label', labelClassName)}>
        <input
          type='checkbox'
          className='Checkbox-input'
          name={name}
          checked={checked}
          onChange={onChange}
          data-qa-advanced-by-agent-checkbox={qaAdvancedByAgent || null}
          data-qa-advanced-by-owner-checkbox={qaAdvancedByOwner || null}
          data-qa-advanced-open-house-checkbox={qaAdvancedOpenHouse || null}
        />
        <span className='Checkbox-icon'/>
        {label || name}
        {additionalLabel && <span className='Text--a1 Text--highlight'>&nbsp;{`${additionalLabel}`}</span>}
      </label>
    </div>
  </div>
)

Checkbox.propTypes = {
  wrapperClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  additionalLabel: PropTypes.string,
  qaAdvancedByAgent: PropTypes.bool,
  qaAdvancedByOwner: PropTypes.bool,
  qaAdvancedOpenHouse: PropTypes.bool
}

export default Checkbox
