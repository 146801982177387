import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Shared/Modal'
import MediaVideoType from '../MediaVideoType'

const VideoTourModal = ({
  isVideoTourModalOpen,
  videoTour,
  deactivateModal
}) => (
  <Modal
    isOpen={isVideoTourModalOpen}
    onRequestClose={deactivateModal}
    ariaHideApp={false}
    portalClassName='ReactModalPortal ReactModalPortal--videoTour'
    overlayClassName='Modal Modal--fullScreen'
  >
    <MediaVideoType
      videoId={videoTour.videoId}
      mediaType={videoTour.mediaType}
      className='VideoTour--modal'
    />
  </Modal>
)

VideoTourModal.propTypes = {
  isVideoTourModalOpen: PropTypes.bool.isRequired,
  videoTour: PropTypes.shape({
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mediaType: PropTypes.string
  }),
  deactivateModal: PropTypes.func.isRequired
}

export default VideoTourModal
