import React, { useEffect } from 'react'
import { observer, Observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { MarkerClusterer, useGoogleMap } from '@react-google-maps/api'
import NearbyListing from './NearbyListing'
import PropTypes from 'prop-types'

const MarkerClusterNearbyListings = ({ mapStore }) => {
  const map = useGoogleMap()
  const hasBounds = () => Boolean(map.getBounds())

  const handleBounds = () => {
    if (hasBounds()) {
      const mapBounds = map.getBounds().toJSON()
      mapStore.fetchNearbyListings(mapBounds)
    }
  }

  useEffect(() => {
    const boundsListener = map.addListener('bounds_changed', handleBounds)
    return () => {
      boundsListener.remove()
    }
  }, [])

  return (
    mapStore.hasMarkers && (
      <MarkerClusterer enableRetinaIcons clusterClass='MapView-markerCluster'>
        {() => (
          <Observer>
            {() => mapStore.markers.map(listing => <NearbyListing key={listing.id} listing={listing}/>)}
          </Observer>
        )}
      </MarkerClusterer>
    )
  )
}

MarkerClusterNearbyListings.propTypes = {
  mapStore: PropTypes.shape({
    hasMarkers: PropTypes.bool,
    fetchNearbyListings: PropTypes.func.isRequired,
    markers: MobxPropTypes.arrayOrObservableArray
  })
}

export default observer(MarkerClusterNearbyListings)
