/* eslint jsx-a11y/no-autofocus: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '../../lib/utils/common'

const Arrow = ({ className, onClick, screenReaderText, withAutoFocus }) => (
  <button
    className={className}
    onClick={onClick}
    autoFocus={withAutoFocus}
  >
    <div className='arrow-inner' />
    <div className='sr-only'>{screenReaderText}</div>
  </button>
)

Arrow.defaultProps = {
  onClick: noop,
  className: ''
}

Arrow.propTypes = {
  className: PropTypes.string,
  screenReaderText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  withAutoFocus: PropTypes.bool
}

export default Arrow
