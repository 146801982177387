/* eslint no-shadow: 0 */

import { action, computed, observable, makeObservable, runInAction } from 'mobx'
import * as R from 'ramda'
import { lens } from 'lorgnette'
import { isServer, getResponseErrors } from '../../../../lib/utils/common'
import { getId } from '../../../../lib/utils/selectors'
import { fromServerToClient, isPresent } from '../../../../lib/utils/collection'
import { withSelectablePricePeriod, withFolderServerMappings, toSelectedItem } from '../../../../lib/utils/stores'
import ListingsPrintStore from '../../ListingsPrint/stores/ListingsPrint'

export const SYNC_TIMEOUT = 1000
export const STORAGE_KEY = 'SelectedBarStore_selectedItems'
export const STORAGE_LIMIT = 500

const enhance = R.compose(withFolderServerMappings, withSelectablePricePeriod)

const sortByPosition = R.sortBy(R.prop('position'))

class SelectedBarStore {
  @observable selectedItems = []
  @observable folders = []
  @observable isCreateModalOpen = false
  @observable isAddToFolderModalOpen = false
  @observable isShareModalOpen = false
  @observable isDeleteItemsModalOpen = false
  @observable isListingsMapActive = false
  @observable isBasketActive = false

  constructor({ transport, sessionStorage, flashMessage }) {
    makeObservable(this)

    this.transport = transport
    this.listingPrintStore = new ListingsPrintStore({ transport })
    this.flashMessage = flashMessage
    this.sessionStorage = sessionStorage
    this.storageLimit = STORAGE_LIMIT
  }

  setInitialData({ folderType, dataSource = 'server' }) {
    this.folderType = folderType
    this.dataSource = dataSource

    if (isServer()) {
      return null
    }

    if (this.dataSource === 'server') {
      return this.transport.ManageBasket
        .fetchBasket()
        .then(this.handleFetchResponse)
    }

    const selectedItems = this.sessionStorage.fetchFromSessionStorage(STORAGE_KEY)

    if (isPresent(selectedItems)) {
      this.setSelectedItems(selectedItems, { sync: false })
    }

    return null
  }

  checkSelectedItem = ({ itemId, itemType, folderId = null }) => {
    const compareFunction = isPresent(folderId)
      ? (obj => obj.itemId === itemId && obj.itemType === itemType && obj.folderId === folderId)
      : (obj => obj.itemId === itemId && obj.itemType === itemType)

    return (
      Boolean(this.selectedItems.find(compareFunction))
    )
  }

  isAllChecked = ({ itemIds, itemType, folderId }) => (
    itemIds.every(id => Boolean(
      this.selectedItems.find(obj => obj.itemId === id && obj.itemType === itemType && obj.folderId === folderId)
    ))
  )

  isSomeChecked = ({ itemIds, itemType, folderId }) => (
    itemIds.some(id => Boolean(
      this.selectedItems.find(obj => obj.itemId === id && obj.itemType === itemType && obj.folderId === folderId)
    ))
  )

  sync = selectedItems => {
    if (this.dataSource === 'server') {
      return this.transport.ManageBasket
        .addToBasket({
          basket: this.mapBasketItemsToServerValues(selectedItems)
        })
    }

    if (selectedItems.length <= this.storageLimit) {
      this.sessionStorage.saveToSessionStorage(STORAGE_KEY, selectedItems)
    }

    return null
  }

  @computed
  get uniqCollection() {
    return R.uniqBy(
      ({ itemId, itemType }) => ({ itemId, itemType }),
      this.selectedItems
    )
  }

  @computed
  get hasDuplicates() {
    return this.uniqCollection.length !== this.selectedItems.length
  }

  @computed
  get selectedListings() {
    return this.selectedItems.map(({ item }) => item)
  }

  @computed
  get selectedIds() {
    return this.selectedItems.map(({ itemId }) => itemId)
  }

  @computed
  get selectedFoldersNames() {
    return R.compose(
      R.join(','),
      R.uniq,
      R.filter(el => el),
      R.pluck('folderName')
    )(this.selectedItems)
  }

  @action('[SelectedBarStore] Update selected items')
  setSelectedItems = (value, { sync = true } = {}) => {
    if (sync) {
      const syncResult = this.sync(value)

      if (syncResult) {
        return syncResult.then(action('[SelectedBarStore] Handle update response', response => {
          if (response && response.success) {
            this.isBasketActive = isPresent(value)
            this.selectedItems = value
          } else {
            const errors = getResponseErrors(response)
            this.flashMessage.show({ text: errors, type: 'error' })
          }

          return response
        }))
      }

      this.isBasketActive = isPresent(value)
      this.selectedItems = value
    } else {
      this.selectedItems = value
    }

    return null
  }

  @action('[SelectedBarStore] remove duplicates')
  removeDuplicates = () => {
    this.setSelectedItems(this.uniqCollection)
  }

  @action('[SelectedBarStore] Update selected items with server value')
  handleFetchResponse = data => {
    this.setSelectedItems(this.mapFromServerToSelectedItems(data), { sync: false })
  }

  @action('[SelectedBarStore] Toggle Basket active')
  toggleBasketActive = () => {
    this.isBasketActive = !this.isBasketActive
  }

  @action('[SelectedBarStore] Set Basket inactive')
  setBasketInactive = () => {
    this.isBasketActive = false
  }

  @action('[SelectedBarStore] Toggle isCreateModalOpen')
  toggleCreateModalOpen = () => {
    this.isCreateModalOpen = !this.isCreateModalOpen
  }

  @action('[SelectedBarStore] Toggle isAddToFolderModalOpen')
  toggleAddToFolderModalOpen = () => {
    this.isAddToFolderModalOpen = !this.isAddToFolderModalOpen
  }

  @action('[SelectedBarStore] Toggle isShareModalOpen')
  toggleShareModalOpen = () => {
    this.isShareModalOpen = !this.isShareModalOpen
  }

  @action('[SelectedBarStore] Toggle isDeleteItemsModalOpen')
  toggleDeleteItemsModalOpen = () => {
    this.isDeleteItemsModalOpen = !this.isDeleteItemsModalOpen
  }

  @action('[SelectedBarStore] Toggle isListingsMapActive')
  toggleListingsMapActive = () => {
    this.isListingsMapActive = !this.isListingsMapActive
  }

  toggleDiverseSelectedItemsCollection = ({ isItemsChecked, selectedItems, folderId, folderName = null }) => {
    if (isItemsChecked) {
      const newSelectedItems = selectedItems.reduce((acc, { itemType, items }) => ([
        ...acc,
        ...items.map(item => ({
          folderId,
          folderName,
          itemId: item.id,
          position: item.position,
          itemType,
          item
        }))
      ]), [])

      const uniqSelectedItems = R.uniqBy(
        ({ folderId, itemId, itemType }) => ({ folderId, itemId, itemType }),
        [...this.selectedItems, ...newSelectedItems]
      )

      return this.setSelectedItems(sortByPosition(uniqSelectedItems))
    }

    const items = selectedItems.reduce((acc, { items, itemType }) => ([
      ...acc,
      ...items.map(({ id }) => ({ id, itemType }))
    ]), [])

    const includesItem = obj => items.find(({ id, itemType }) => id === obj.itemId && itemType === obj.itemType)
    const compareFunction = isPresent(folderId)
      ? obj => (includesItem(obj) && obj.folderId === folderId)
      : obj => includesItem(obj)

    const [itemsToRemove, filteredCollection] = R.partition(compareFunction, this.selectedItems)

    return this.removeFromBasket(itemsToRemove, filteredCollection)
  }

  toggleSelectedItemsCollection = ({ isItemsChecked, items, itemType, folderId, folderName = null }) => {
    if (isItemsChecked) {
      const newSelectedItems = items.map(item => toSelectedItem({
        folderId,
        folderName,
        itemType,
        item: item,
        position: item.position
      }))

      const selectedItems = R.uniqBy(
        ({ folderId, itemId, itemType }) => ({ folderId, itemId, itemType }),
        [...this.selectedItems, ...newSelectedItems]
      )

      return this.setSelectedItems(sortByPosition(selectedItems))
    }

    const itemsIds = items.map(getId)
    const compareFunction = isPresent(folderId)
      ? obj => (itemsIds.includes(obj.itemId) && obj.itemType === itemType && obj.folderId === folderId)
      : obj => itemsIds.includes(obj.itemId)

    const [itemsToRemove, filteredCollection] = R.partition(compareFunction, this.selectedItems)

    return this.removeFromBasket(itemsToRemove, filteredCollection)
  }

  toggleSelectedItems = ({ isItemChecked, item, itemType, folderId, folderName = null, position = null }) => {
    if (isItemChecked) {
      const selectedItem = toSelectedItem({
        folderId,
        folderName,
        position,
        itemType,
        item
      })
      return this.setSelectedItems(sortByPosition([...this.selectedItems, selectedItem]))
    }

    const compareFunction = isPresent(folderId)
      ? obj => (obj.itemId === item.id && obj.itemType === itemType && obj.folderId === folderId)
      : obj => (obj.itemId === item.id && obj.itemType === itemType)

    const [itemsToRemove, filteredCollection] = R.partition(compareFunction, this.selectedItems)

    if (itemType === 'Search') {
      return this.setSelectedItems(sortByPosition(filteredCollection))
    }

    return this.removeFromBasket(itemsToRemove, filteredCollection)
  }

  toggleSelectedItemsInFolder = ({ isItemsChecked, itemType, folder }) => {
    return this.toggleSelectedItemsCollection({
      isItemsChecked,
      itemType,
      items: this.selectedItems
        .filter(item => item.folderId === folder.id)
        .map(({ item }) => item),
      folderId: folder.id,
      folderName: folder.name
    })
  }

  updateSelectedItemsPosition = items => {
    this.setSelectedItems(
      sortByPosition(
        items.reduce(
          (selectedItems, { folderId, position, itemId }) => (
            lens.firstOf(v => v.folderId === folderId && v.itemId === itemId)
              .update(selectedItems, item => ({ ...item, position }))
          ), this.selectedItems)
      )
    )
  }

  @action('[SelectedBarStore] renameFolder in selected items')
  renameFolder = (folderId, name) => {
    this.setSelectedItems(
      lens.firstOf(obj => obj.folderId === folderId)
        .prop('folderName')
        .set(this.selectedItems, name)
    )
  }

  resetSelectedItems = () => {
    return this.setSelectedItems([])
  }

  clearBasket = () => {
    this.transport.ManageBasket.clearBasket()
      .then(response => {
        runInAction(() => {
          if (response && response.success) {
            this.selectedItems = []
            this.isBasketActive = false
            this.flashMessage.show({ type: 'success', text: 'Cart is empty' })
          } else {
            this.flashMessage.show({ text: 'Something went wrong', type: 'error' })
          }
        })
      })
  }

  removeFromBasket = (itemsToRemove, filteredCollection) => {
    return this.transport.ManageBasket.removeFromBasket({
      basket: this.groupIdByItemType(itemsToRemove)
    })
      .then(response => {
        runInAction(() => {
          if (response && response.success) {
            this.isBasketActive = isPresent(filteredCollection)
            this.selectedItems = filteredCollection
          } else {
            this.flashMessage.show({ text: 'Something went wrong', type: 'error' })
          }
        })
        return response
      })
  }

  getFolders = (allowAddToFolder = true) => {
    if (!allowAddToFolder) {
      return
    }

    const request = this.folderType === 'Listing' ?
      this.transport.Folders.getListingFolderNames :
      this.transport.Folders.getSearchFolders

    request().then(action(responseData => {
      this.folders = fromServerToClient(responseData)
    }))
  }

  createFolderHandler = (formValue, items) => {
    const request = this.folderType === 'Listing'
      ? this.transport.Folders.createListingFolder
      : this.transport.Folders.createSearchFolder

    const itemsData = items || this.mapItemCollectionToServerValue(this.selectedItems)

    return request({ ...formValue, ...itemsData }).then(action(responseData => {
      const response = fromServerToClient(responseData)
      if (!response.errors) {
        this.folders = [...this.folders, response]
        this.flashMessage.show({ type: 'success', text: 'Folder was created' })
        return response
      }
      throw response.errors
    }))
  }

  addToFolderHandler = (formValue, items) => {
    const request = this.folderType === 'Listing' ?
      this.transport.Folders.updateListingsItems :
      this.transport.Folders.updateSearchesItems

    const itemsData = items || this.mapItemCollectionToServerValue(this.selectedItems)

    return request(formValue.folderId, itemsData).then(responseData => {
      const response = fromServerToClient(responseData)

      if (!response.errors) {
        this.flashMessage.show({ type: 'success', text: 'Item was added to folder' })
        return response
      }
      throw response.errors
    })
  }

  shareItemsHandler = (formValue, pricePeriods, items) => {
    const pricePeriod = isPresent(pricePeriods)
      ? pricePeriods
      : this.selectedPricePeriods

    const preparedPricePeriods = Object.keys(pricePeriod)
      .reduce((acc, curr) => {
        const period = R.propOr(false, 'period', pricePeriod[curr])
        const year = R.propOr(false, 'year', pricePeriod[curr])

        return {
          ...acc,
          ...(period && { [curr]: { period, year } })
        }
      }, {})

    const itemsData = items || this.mapItemCollectionToServerValue(this.selectedItems)
    const request = formValue.shareTo === 'agents'
      ? this.transport.ManageShare.listingsToAgents
      : this.transport.ManageShare.listingsToCustomers
    const data = { ...formValue, ...itemsData, pricePeriods: preparedPricePeriods }

    return request(data).then(responseData => {
      const response = fromServerToClient(responseData)

      if (!response.errors) {
        return response
      }
      throw response.errors
    })
  }
}

export default enhance(SelectedBarStore)
