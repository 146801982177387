import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import Viewport from '../../Shared/Viewport'
import Card from '../../Shared/Card'
import CardPreview from '../../Shared/CardPreview'
import { Close } from '../../Svg'
import { isPresent } from '../../../lib/utils/collection'

const MapCardPreview = ({
  activeItem,
  handleOnSaveClick,
  cardPreviewClass,
  removeMarkAsActive
}) => {
  const closeButtonRef = useRef(null)

  useEffect(() => {
    global.requestAnimationFrame(() => {
      if (closeButtonRef.current) {
        closeButtonRef.current.focus()
      }
    })

    return () => {
      removeMarkAsActive()
    }
  }, [])

  return (
    isPresent(activeItem)
      ? (
        <div className={className('MapContainer-listingPreview', cardPreviewClass)}>
          <Viewport except='mobile'>
            <CardPreview item={activeItem} onSave={handleOnSaveClick}/>
          </Viewport>

          <Viewport only='mobile'>
            <Card item={activeItem} onSave={handleOnSaveClick} className='Card--mobileCardPreview'/>
          </Viewport>

          <button type='button' ref={closeButtonRef} className='MapContainer-close' onClick={removeMarkAsActive}>
            <Close className='MapContainer-closeIcon'/>
          </button>
        </div>
      )
      : null
  )
}

MapCardPreview.propTypes = {
  activeItem: PropTypes.object,
  handleOnSaveClick: PropTypes.func.isRequired,
  removeMarkAsActive: PropTypes.func.isRequired,
  cardPreviewClass: PropTypes.string,
  closeButtonRef: PropTypes.object
}

MapCardPreview.defaultProp = {
  cardPreviewClass: ''
}

export default MapCardPreview
