import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withProps, withHandlers, lifecycle, compose, defaultProps, withState } from 'recompose'
import cn from 'classnames'
import { noop } from '../../lib/utils/common'
import { watchKey } from '../../lib/utils/dom'
import KEY from '../../lib/constants/key'
import { Close } from '../../components/Svg'
import { Viewport } from '../../components/Shared/Viewport'
import LightBoxCarousel from './LightBoxCarousel'
import LightBoxSlider from './LightBoxSlider'
import LightBoxMapButton from './LightBoxMapButton'
import LightBoxVideoTourButton from './LightBoxVideoTourButton'

const enhance = compose(
  inject('store'),
  withState('lightBoxSlider', 'setLightBoxSlider', null),
  withState('lightBoxCarousel', 'setLightBoxCarousel', null),
  withProps(({ store }) => ({
    photoGalleryUIStateStore: store.photoGalleryUIStateStore,
    videoTour: store.photoGalleryUIStateStore.videoTour,
    photoSliderContainerRef: React.createRef()
  })),
  defaultProps({
    photoSliderGoTo: noop
  }),
  withHandlers({
    registerLightBoxSliderRef: ({ setLightBoxSlider }) => ref => {
      setLightBoxSlider(ref)
    },
    registerLightBoxCarouselRef: ({ setLightBoxCarousel }) => ref => {
      setLightBoxCarousel(ref)
    },
    handleArrows: ({ lightBoxSlider }) => e => {
      watchKey(e, KEY.LEFT, () => {
        lightBoxSlider.slickPrev()
      })

      watchKey(e, KEY.RIGHT, () => {
        lightBoxSlider.slickNext()
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      const { photoSliderContainerRef, handleArrows } = this.props

      document.body.classList.add('isLightBoxOpen')

      photoSliderContainerRef.current.addEventListener('keydown', handleArrows)
    },
    componentWillUnmount() {
      const { photoSliderContainerRef, handleArrows } = this.props

      document.body.classList.remove('isLightBoxOpen')

      photoSliderContainerRef.current.removeEventListener('keydown', handleArrows)
    }
  }),
  observer
)

const LightBox = ({
  videoTour,
  photoGalleryUIStateStore,
  toggleLightBoxActive,
  registerLightBoxSliderRef,
  registerLightBoxCarouselRef,
  lightBoxSlider,
  lightBoxCarousel,
  header,
  addressOrTitle,
  photoSliderContainerRef
}) => (
  <div className='LightBox'>
    <div className='LightBox-content'>
      <div className='LightBox-headerWrap'>
        <div className='LightBox-header'>
          { header }
        </div>
        <button
          className='LightBox-closeButton'
          onClick={() => { toggleLightBoxActive(false) }}
        >
          <div className='LightBox-closeButton--inner' tabIndex='-1'>
            <span className='sr-only'>Close</span>
            <Close className='LightBox-closeIcon'/>
          </div>
        </button>
      </div>
      <div className='LightBox-slider' ref={photoSliderContainerRef}>
        <LightBoxSlider
          addressOrTitle={addressOrTitle}
          registerLightBoxSliderRef={registerLightBoxSliderRef}
          lightBoxCarousel={lightBoxCarousel}
          lightBoxSlider={lightBoxSlider}
        />
      </div>
      <Viewport only='desktop'>
        <div className='LightBox-sliderPreview'>
          <div className='LightBox-sliderPreviewContent'>
            <LightBoxMapButton/>
            { videoTour && <LightBoxVideoTourButton /> }
            <div className={cn('LightBox-carousel', {
              'LightBox-carousel--withVideoTourButton': videoTour
            })}>
              <LightBoxCarousel
                addressOrTitle={addressOrTitle}
                currentImageIndex={photoGalleryUIStateStore.currentImageIndex}
                registerLightBoxCarouselRef={registerLightBoxCarouselRef}
                lightBoxSlider={lightBoxSlider}
              />
            </div>
          </div>
        </div>
      </Viewport>
    </div>
  </div>
)

LightBox.propTypes = {
  photoGalleryUIStateStore: PropTypes.shape({
    currentImageIndex: PropTypes.number
  }),
  videoTour: PropTypes.any,
  toggleLightBoxActive: PropTypes.func.isRequired,
  mapInfo: PropTypes.any,
  viewOnGmapsInfo: PropTypes.object,
  interestingPlaces: PropTypes.any,
  interestingPlacesGroups: PropTypes.array,
  addressOrTitle: PropTypes.any,
  registerLightBoxSliderRef: PropTypes.func,
  registerLightBoxCarouselRef: PropTypes.func,
  lightBoxSlider: PropTypes.object,
  lightBoxCarousel: PropTypes.object,
  header: PropTypes.node,
  photoSliderContainerRef: PropTypes.object
}

export default enhance(LightBox)
