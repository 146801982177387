import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Radio = ({ checked, className, name, onChange, value, label }) => (
  <div className={cn('Radio', className)}>
    <label className='Radio-label'>
      <input
        type='radio'
        className='Radio-input'
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        aria-checked={checked}
      />
      <span className='Radio-icon' />
      {label}
    </label>
  </div>
)

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default Radio
