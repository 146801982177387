import * as R from 'ramda'
import { observer } from 'mobx-react'
import { cond, renderComponent, setDisplayName } from '../../components/Hocs'
import YouTube from '../YouTube'
import Vimeo from '../Vimeo'

const MediaVideoType = R.compose(
  setDisplayName('MediaVideoType'),
  observer,
  cond(
    ({ mediaType }) => mediaType,
    {
      youtube: renderComponent(YouTube),
      vimeo: renderComponent(Vimeo)
    }
  )
)

export default MediaVideoType()
