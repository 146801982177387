/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { GoogleMap, InfoBox, Marker, StreetViewPanorama } from '@react-google-maps/api'
import { isPresent } from '../../../lib/utils/collection'
import { onKey } from '../../../lib/utils/dom'
import KEY from '../../../lib/constants/key'
import { refProp, childrenPropType } from '../../../lib/propTypes'
import RoundCloseButton from '../../Shared/RoundCloseButton'
import mapStyles from '../mapStyles'
import { LocationPeachUrl } from '../../../components/Svg'
import MarkerLabel from './MarkerLabel'
import ControlPanel from './ControlPanel'
import { useGoogleMap } from '../../Hooks/useGoogleMap'
import MarkerClusterInterestingPlaces from './MarkerClusterInterestingPlaces'
import MapControls from '../MapControls'
import { defaultZoom, commonGoogleMapOptions } from '../constants'

const containerStyle = {
  height: '100%', position: 'relative', overflow: 'hidden'
}

const googleMapOptions = {
  ...commonGoogleMapOptions,
  styles: mapStyles
}

const MapView = ({
  className,
  mapStore,
  changeContentType,
  mapButtonRef,
  children
}) => {
  const closeButtonRef = useRef(null)
  const mapContainerRef = useRef(null)
  const [isStreetView, setIsStreetView] = useState(false)
  const center = { lat: +mapStore.mapInfo.lat, lng: +mapStore.mapInfo.lon }

  const { map, isLoaded, onMapLoad, onMapUnmount } = useGoogleMap()

  const handleMapLoad = useCallback(mapInstance => {
    onMapLoad(mapInstance)

    setTimeout(() => {
      mapInstance.panTo(center)
    }, 100)
  }, [])

  const handleCloseMap = () => {
    changeContentType('')
    document.body.classList.remove('isModalActive')

    global.requestAnimationFrame(() => {
      if (mapButtonRef.current) {
        mapButtonRef.current.focus()
      }
    })
  }

  const handleCloseStreetView = () => {
    setIsStreetView(false)
  }

  const closeMapOnKeyEscape = e => {
    onKey(e, KEY.ESC, () => {
      if (isStreetView) {
        handleCloseStreetView()
      } else {
        handleCloseMap()
      }
    })
  }

  const handleKeyDownCloseMap = e => {
    onKey(e, KEY.RETURN, handleCloseMap)
    onKey(e, KEY.SPACE, handleCloseMap)
    onKey(e, KEY.ESC, handleCloseMap)
  }

  const onVisibleChanged = () => {
    const isVisibleStreetView = map && map.getStreetView().getVisible()

    setIsStreetView(isVisibleStreetView)

    setTimeout(() => {
      if (closeButtonRef.current && isVisibleStreetView) {
        closeButtonRef.current.focus()
      }
    }, 100)
  }

  useEffect(() => {
    const instance = mapContainerRef.current
    instance.addEventListener('keydown', closeMapOnKeyEscape)

    return () => {
      instance.removeEventListener('keydown', closeMapOnKeyEscape)
    }
  }, [])

  return (
    <div ref={mapContainerRef} className={cn('MapView', className)}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          tilt={0}
          zoom={defaultZoom}
          onLoad={handleMapLoad}
          onUnmount={onMapUnmount}
          options={{
            ...googleMapOptions,
            rotateControlOptions: { position: global.google.maps.ControlPosition.RIGHT_BOTTOM }
          }}
        >
          <StreetViewPanorama
            visible={isStreetView}
            onVisibleChanged={onVisibleChanged}
            options={{ fullscreenControl: false }}
          />

          <Marker position={center}
            icon={{
              url: LocationPeachUrl,
              scaledSize: { width: 30, height: 42 }
            }}
          >
            <InfoBox options={{
              boxStyle: { overflow: 'unset', width: '240px' },
              closeBoxURL: '',
              pixelOffset: global.google && new global.google.maps.Size(-120, -58),
              enableEventPropagation: true,
              alignBottom: true
            }}>
              <MarkerLabel
                address={mapStore.viewOnGmapsInfo.address || mapStore.mapInfo.address}
                url={mapStore.viewOnGmapsInfo.url}
                label={mapStore.viewOnGmapsInfo.label}
              />
            </InfoBox>
          </Marker>

          <MarkerClusterInterestingPlaces
            interestingPlaces={mapStore.interestingPlaces}
            interestingPlacesFilters={mapStore.interestingPlacesFilters}
          />

          {children && children}

          {!isStreetView && <MapControls className='MapControls-mapView'/>}

        </GoogleMap>
      )}

      {isPresent(mapStore.interestingPlaces) && (
        <div className='MapView-controlPanel'>
          <ControlPanel
            toggleInterestingPlacesFilters={mapStore.toggleInterestingPlacesFilters}
            interestingPlacesFilters={mapStore.interestingPlacesFilters}
            interestingPlacesGroups={mapStore.interestingPlacesGroups}
          />
          <div id='listingCard' className='MapView-listingPreviewCard'/>
        </div>
      )}

      {mapStore.isCloseMapButtonVisible && (
        <RoundCloseButton
          closeButtonRef={closeButtonRef}
          onClick={isStreetView ? handleCloseStreetView : handleCloseMap}
          onKeyDown={handleKeyDownCloseMap}
          autoFocus
        />
      )}
    </div>
  )
}

MapView.propTypes = {
  className: PropTypes.string,
  mapStore: PropTypes.shape({
    mapInfo: PropTypes.object.isRequired,
    viewOnGmapsInfo: PropTypes.object.isRequired,
    interestingPlaces: PropTypes.array.isRequired,
    interestingPlacesFilters: PropTypes.object.isRequired,
    interestingPlacesGroups: PropTypes.array.isRequired,
    toggleInterestingPlacesFilters: PropTypes.func.isRequired,
    isCloseMapButtonVisible: PropTypes.bool,
    nearbyListingsUrl: PropTypes.string
  }),
  changeContentType: PropTypes.func.isRequired,
  mapButtonRef: refProp,
  children: childrenPropType
}

export default observer(MapView)
