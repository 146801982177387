import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { withProps, lifecycle, compose, setPropTypes } from 'recompose'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import Slider from 'react-slick'
import Breakpoints from '../../lib/breakpoints'
import { PlaceHolderHouseUrl } from '../../components/Svg'
import { waitImagesLoad, withRemountOnResize } from '../../components/Hocs'
import SliderIndicator from '../SliderIndicator'
import { LoadBlock } from './LoadBlock'
import ArrowArea from './ArrowArea'
import { imageUrlPropType } from '../../lib/propTypes'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    photoGalleryUIStateStore: store.photoGalleryUIStateStore,
    mapStore: store.mapStore,
    images: store.photoGalleryUIStateStore.images,
    photoSliderRef: React.createRef()
  })),
  setPropTypes({
    store: PropTypes.shape({
      photoGalleryUIStateStore: PropTypes.shape({
        currentImageIndex: PropTypes.number.isRequired,
        updateCurrentImage: PropTypes.func.isRequired
      })
    })
  }),
  withRemountOnResize,
  withProps(
    ({ photoGalleryUIStateStore: { currentImageIndex, updateCurrentImage }, lightBoxCarousel, images }
    ) => ({
      settings: {
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        slidesToScroll: 1,
        swipe: false,
        asNavFor: lightBoxCarousel,
        className: 'PhotoSlider-container PhotoSlider-container--lightBox',
        nextArrow: <ArrowArea
          withAutoFocus
          screenReaderText='Next Slide'
          wrapperClassName='PhotoSlider-arrowArea--next'/>,
        prevArrow: <ArrowArea screenReaderText='Previous Slide' wrapperClassName='PhotoSlider-arrowArea--prev'/>,
        afterChange: index => {
          updateCurrentImage(index)
        },
        initialSlide: currentImageIndex,
        responsive: [
          {
            breakpoint: Breakpoints.mobile,
            settings: {
              variableWidth: false,
              swipe: true
            }
          },
          {
            breakpoint: Breakpoints.tablet,
            settings: {
              variableWidth: false,
              swipe: true
            }
          }
        ]
      },
      slideDescription: R.propOr('', 'caption', images[currentImageIndex])
    })),

  waitImagesLoad({
    getImages: ({ images }) => images,
    callback: ({ forceOnResizeKey }) => { forceOnResizeKey() },
    getPreviewVariant: ({ photoGalleryUIStateStore }) => photoGalleryUIStateStore.previewVariant,
    placeholderImage: PlaceHolderHouseUrl
  }),

  lifecycle({
    componentDidMount() {
      const { photoSliderRef, mapStore } = this.props
      const slides = photoSliderRef.current
        .querySelectorAll('.slick-slide')
      slides.forEach(slide => {
        slide.setAttribute('aria-live', 'polite')
      })

      mapStore.toggleCloseMapButtonVisible(false)
    },
    componentWillUnmount() {
      const { mapStore } = this.props
      mapStore.toggleCloseMapButtonVisible(true)
    }
  }),
  observer
)

export const LightBoxSlider = ({
  photoGalleryUIStateStore,
  addressOrTitle,
  settings,
  registerLightBoxSliderRef,
  onResizeKey,
  imagesLoaded,
  images,
  forceOnResizeKey,
  photoSliderRef,
  slideDescription
}) => (
  <div
    key={onResizeKey}
    className='PhotoSlider'
    ref={photoSliderRef}
    role='region'
    aria-label='Lightbox'
    aria-roledescription='slider'
  >
    <div className='sr-only' aria-live='polite' aria-atomic='true'>
      {
        `Photo slider slide ${photoGalleryUIStateStore.currentImageIndex + 1}
        of ${images.length}, ${addressOrTitle}${slideDescription && ', ' + slideDescription}`
      }
    </div>
    <LoadBlock isActive={images.length > 0 && !imagesLoaded } />
    <Slider ref={registerLightBoxSliderRef} {...settings} >
      {images.map((media, index) => (
        <div key={media.id} className='PhotoSlider-slideContainer'>
          <picture>
            <source srcSet={photoGalleryUIStateStore.imageUrls[index].webp} type='image/webp'/>
            <img
              src={photoGalleryUIStateStore.imageUrls[index].jpeg}
              className='PhotoSlider-image'
              onError={() => {
                photoGalleryUIStateStore.replaceImageUrl(index, PlaceHolderHouseUrl)
                forceOnResizeKey()
              }}
              alt={`${addressOrTitle} ${media.caption || ''}`}
            />
          </picture>
        </div>
      ))}
    </Slider>
    <SliderIndicator classNames='tablet-viewport-flex' totalCount={images.length} />
  </div>
)

const imagesPropTypes = MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: imageUrlPropType,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caption: PropTypes.string,
  card: imageUrlPropType,
  carousel: imageUrlPropType,
  gallery: imageUrlPropType,
  originalFilename: PropTypes.string,
  preview: imageUrlPropType,
  thumb: imageUrlPropType
})).isRequired

LightBoxSlider.propTypes = {
  images: imagesPropTypes,
  photoGalleryUIStateStore: PropTypes.shape({
    imageUrls: MobxPropTypes.arrayOrObservableArrayOf(imageUrlPropType),
    replaceImageUrl: PropTypes.func,
    currentImageIndex: PropTypes.number,
    images: imagesPropTypes
  }),
  registerLightBoxSliderRef: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  imagesLoaded: PropTypes.bool.isRequired,
  onResizeKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  addressOrTitle: PropTypes.string.isRequired,
  forceOnResizeKey: PropTypes.func.isRequired,
  photoSliderRef: PropTypes.object,
  slideDescription: PropTypes.string
}

export default enhance(LightBoxSlider)
