import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Arrow from '../Arrow'

const ArrowArea = ({ wrapperClassName = '', ...props }) => (
  <div className={cn('PhotoSlider-arrowArea', wrapperClassName)}>
    <Arrow {...props}/>
  </div>
)

ArrowArea.propTypes = {
  wrapperClassName: PropTypes.string
}

export default ArrowArea
