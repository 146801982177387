import React from 'react'
import PropTypes from 'prop-types'
import { withProps, compose, lifecycle, withHandlers } from 'recompose'
import { PropTypes as MobxPropTypes, inject } from 'mobx-react'
import Slider from 'react-slick'
import cn from 'classnames'
import { PlaceHolderHouseUrl } from '../../components/Svg'
import { imageUrlPropType } from '../../lib/propTypes'

const countSlideShow = 8

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    photoGalleryUIStateStore: store.photoGalleryUIStateStore,
    images: store.photoGalleryUIStateStore.images,
    carouselRef: React.createRef()
  })),
  withHandlers({
    afterChangeHandler: ({ carouselRef }) => () => {
      const currentSlide = carouselRef.current.querySelector('.slick-current')

      const { activeElement } = document
      const isSlide = activeElement.classList.contains('slick-slide')

      if (isSlide) {
        const slideButton = currentSlide.querySelector('[data-slide]')
        slideButton.focus()
      }
    }
  }),
  withProps(({ afterChangeHandler, lightBoxSlider, currentImageIndex, images }) => ({
    settings: {
      arrows: false,
      lazyLoad: true,
      speed: 500,
      slidesToShow: countSlideShow,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      asNavFor: lightBoxSlider,
      infinite: images.length > countSlideShow,
      initialSlide: currentImageIndex,
      afterChange: () => {
        afterChangeHandler()
      },
      className: 'Carousel-content Carousel-content--lightBox'
    }
  })),
  lifecycle({
    componentDidMount() {
      const { carouselRef } = this.props
      const slickTrack = carouselRef.current.querySelector('.slick-track')
      const slides = carouselRef.current.querySelectorAll('.slick-slide')
      slickTrack.setAttribute('role', 'list')
      slides.forEach(slide => {
        slide.setAttribute('role', 'listitem')
      })
    }
  })
)

export const LightBoxCarousel = ({
  photoGalleryUIStateStore,
  images,
  settings,
  registerLightBoxCarouselRef,
  addressOrTitle,
  carouselRef,
  currentImageIndex
}) => (
  <div
    className='Carousel Carousel--lightBox'
    role='region'
    aria-label='Lightbox'
    aria-roledescription='carousel'
    ref={carouselRef}
  >
    <Slider ref={registerLightBoxCarouselRef} {...settings} >
      {images.map((media, index) => (
        <div
          key={media.id}
          className={cn('Carousel-slideContainer', { isActive: currentImageIndex === index })}
          role='group'
          aria-label={`${addressOrTitle} ${media.caption || ''}`}
          aria-roledescription='slide'
        >
          <button className='Carousel-slideButton' data-slide='true'>
            <div className='Carousel-imageContainer' tabIndex='-1'>
              <picture>
                <source srcSet={media.thumb.webp} type='image/webp'/>
                <img
                  className='Carousel-image'
                  src={media.thumb.jpeg}
                  onError={() => {
                    photoGalleryUIStateStore.replaceImageUrl(index, PlaceHolderHouseUrl)
                  }}
                  alt={`${addressOrTitle} ${media.caption || ''}`}
                  loading={index === 0 ? null : 'lazy'}
                />
              </picture>
            </div>
          </button>
        </div>
      ))}
    </Slider>
  </div>
)

LightBoxCarousel.propTypes = {
  photoGalleryUIStateStore: PropTypes.shape({
    replaceImageUrl: PropTypes.func.isRequired
  }),
  images: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    thumb: imageUrlPropType,
    position: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  currentImageIndex: PropTypes.number.isRequired,
  settings: PropTypes.object.isRequired,
  registerLightBoxCarouselRef: PropTypes.func.isRequired,
  addressOrTitle: PropTypes.string.isRequired,
  carouselRef: PropTypes.object
}

export default enhance(LightBoxCarousel)
