import React from 'react'
import { MARKER_COLORS } from './constants'
import { MarkerTemplate } from '../../Svg'
import { fixedEncodeURIComponent } from '../../../lib/utils/common'

export const containerForLayout = layout => (
  layout === 'fixedHeight' ? (
    <div style={{ height: '450px' }} />
  ) : (
    <div style={{ height: 'calc(100vh - 148px)' }} />
  )
)

export const isActiveMarker = ({ hoveredListingId, activeItem }, { id }) => (
  hoveredListingId === id || (activeItem && activeItem.id === id)
)

export const getMarkerSvgStyles = (store, listing) => {
  const active = isActiveMarker(store, listing)
  const viewedColor = listing.viewed ? MARKER_COLORS.viewed : MARKER_COLORS.inactive
  const fillColor = active ? MARKER_COLORS.active : viewedColor
  const textColor = listing.viewed && !active ? MARKER_COLORS.viewedText : MARKER_COLORS.text
  const scaleFactor = active ? 1.3 : 1

  return { fillColor, textColor, scaleFactor }
}

export const buildMarkerSvgBinary = ({ store = {}, listing = {} }) => {
  const style = getMarkerSvgStyles(store, listing)
  const marker = MarkerTemplate({
    text: listing.shortPrice,
    showBottomArrow: listing.displayAddress,
    style
  })

  return `data:image/svg+xml;charset=utf-8,${fixedEncodeURIComponent(marker)}`
}
