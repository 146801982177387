import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import VideoIconUrl from 'images/modules/Shared/i-video.svg'

const VideoTourButton = ({ className, onClick }) => (
  <button className={cn('VideoTourButton', className)} onClick={onClick}>
    <span className='VideoTourButton-text'>Video Tour</span>
    <img className='VideoTourButton-icon' src={VideoIconUrl} alt='' />
  </button>
)

VideoTourButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default VideoTourButton
