import { useEffect } from 'react'
import { isPresent } from '../../../../lib/utils/collection'
import polygons from '../../../../lib/config/polygons.json'
import getLatLngLiteral from '../../../../lib/dataMappings/getLatLngLiteral'

const useMarkersBounds = ({ map, mapBounds, markers }) => {
  const mapSetBounds = () => {
    map.panToBounds(mapBounds, 0)
    map.fitBounds(mapBounds, 0)
  }

  const mapFitBounds = bounds => {
    map.panToBounds(bounds)
    map.fitBounds(bounds)
  }

  useEffect(() => {
    if (!map) {
      return
    }

    const bounds = global.google && new global.google.maps.LatLngBounds()

    if (isPresent(mapBounds)) {
      mapSetBounds()
      return
    }

    if (isPresent(markers)) {
      markers.forEach(item => {
        bounds.extend(getLatLngLiteral(item))
      })
    } else {
      polygons.thehamptons.points.forEach(point => {
        bounds.extend(getLatLngLiteral(point))
      })
    }

    mapFitBounds(bounds)
  }, [map])
}

export default useMarkersBounds
