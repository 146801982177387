import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Viewport from '../../components/Shared/Viewport'
import CardPreview from '../../components/Shared/SearchResultsMap/CardPreview'
import CardWithPortal from './MapView/CardWithPortal'

const ListingCardPreview = ({ listing, handleOnSaveClick, handleSetInactive, cardPreviewClass }) => {
  return (
    <Fragment>
      <Viewport except='mobile'>
        <CardPreview
          activeItem={listing}
          handleOnSaveClick={handleOnSaveClick}
          removeMarkAsActive={handleSetInactive}
          cardPreviewClass={cardPreviewClass}
        />
      </Viewport>
      <Viewport only='mobile'>
        <CardWithPortal
          listing={listing}
          handleOnSaveClick={handleOnSaveClick}
          handleSetInactive={handleSetInactive}
          cardPreviewClass={cardPreviewClass}
        />
      </Viewport>
    </Fragment>
  )
}

ListingCardPreview.propTypes = {
  listing: PropTypes.object.isRequired,
  handleOnSaveClick: PropTypes.func.isRequired,
  handleSetInactive: PropTypes.func.isRequired,
  cardPreviewClass: PropTypes.string
}

export default observer(ListingCardPreview)
