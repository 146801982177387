import React from 'react'
import PropTypes from 'prop-types'
import ReactYouTube from 'react-youtube'
import cn from 'classnames'
import { compose, lifecycle, withHandlers, setPropTypes, defaultProps, withState } from 'recompose'
import ErrorVideo from '../ErrorVideo'

const enhance = compose(
  defaultProps({
    isInFocus: true
  }),
  setPropTypes({
    isInFocus: PropTypes.bool.isRequired
  }),
  withState('hasError', 'setError', false),
  withHandlers(() => {
    let player

    return {
      setPlayer: () => ({ target }) => { player = target },
      pauseVideo: () => () => { if (player) { player.pauseVideo() } },
      onError: ({ setError }) => () => { setError(true) }
    }
  }),
  lifecycle({
    componentDidUpdate() {
      if(!this.props.isInFocus) { this.props.pauseVideo() }
    }
  })
)

const YouTube = ({
  height,
  width,
  className,
  videoId,
  setPlayer,
  onError,
  hasError
}) => (
  <div className={cn('VideoTour', className)}>
    { hasError && <ErrorVideo /> }
    <ReactYouTube
      opts={{ height, width }}
      containerClassName='VideoTour-player'
      videoId={videoId}
      onReady={setPlayer}
      onError={onError}
    />
  </div>
)

YouTube.defaultProps = {
  height: '100%',
  width: '100%',
  playerVars: {
    rel: 0
  }
}

YouTube.propTypes = {
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  isInFocus: PropTypes.bool,
  setPlayer: PropTypes.func,
  playerVars: PropTypes.shape({
    rel: PropTypes.number
  }),
  onError: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired
}

export default enhance(YouTube)
