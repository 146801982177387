import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { withHandlers, compose, setPropTypes } from 'recompose'
import cn from 'classnames'
import { toggleClass } from '../../../lib/utils/sprinkles'

const enhance = compose(
  setPropTypes({
    interestingPlacesFilters: MobxPropTypes.objectOrObservableObject.isRequired,
    toggleInterestingPlacesFilters: PropTypes.func.isRequired
  }),
  withHandlers({
    handleOnToggleItem: ({ toggleInterestingPlacesFilters }) => id => event => {
      toggleClass('isDisabled', event.currentTarget.closest('.ListWithIcon-item'))
      toggleInterestingPlacesFilters(id)
    },
    isDisabledItem: ({ interestingPlacesFilters }) => id => (
      !interestingPlacesFilters[id]
    )
  })
)

const ControlPanel = ({ handleOnToggleItem, isDisabledItem, interestingPlacesGroups = [] }) => (
  <div className='ListWithIcon ListWithIcon--mapView'>
    <div className='ListWithIcon-content'>
      <div className='ListWithIcon-title'>Show nearby</div>
      <ul className='ListWithIcon-items'>
        {interestingPlacesGroups.map(item => (
          <li key={item.id} className={cn('ListWithIcon-item', { isDisabled: isDisabledItem(item.id) })} >
            <button
              className={`ListWithIcon-itemButton ListWithIcon-itemButton--${item.id}`}
              onClick={handleOnToggleItem(item.id)}
              aria-pressed={isDisabledItem(item.id)}
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

ControlPanel.propTypes = {
  handleOnToggleItem: PropTypes.func.isRequired,
  isDisabledItem: PropTypes.func.isRequired,
  interestingPlacesGroups: PropTypes.array.isRequired
}

export default enhance(ControlPanel)
