import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { isPresent } from '../../../../lib/utils/collection'

const StatusInfo = ({ status, position }) => {
  return (
    isPresent(status)
      ? <div className={cn('StatusFlag', `StatusFlag--${status.type}`, { [`StatusFlag--${position}`]: position })}>
        <div className='StatusFlag-text'>
          {status.label}
        </div>
      </div>
      : null
  )
}

StatusInfo.propTypes = {
  position: PropTypes.string,
  status: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string
  })
}

export default StatusInfo
