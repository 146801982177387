import React from 'react'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import { withPortal } from '../../../components/Hocs'
import CardPreview from '../../../components/Shared/SearchResultsMap/CardPreview'
import PropTypes from 'prop-types'

const enhance = R.compose(withPortal('#listingCard'), observer)

const CardWithPortal = ({ listing, handleOnSaveClick, handleSetInactive, cardPreviewClass }) => {
  return (
    <CardPreview
      activeItem={listing}
      handleOnSaveClick={handleOnSaveClick}
      removeMarkAsActive={handleSetInactive}
      cardPreviewClass={cardPreviewClass}
    />)
}

CardWithPortal.propTypes = {
  listing: PropTypes.object.isRequired,
  handleOnSaveClick: PropTypes.func.isRequired,
  handleSetInactive: PropTypes.func.isRequired,
  cardPreviewClass: PropTypes.string
}

export default enhance(CardWithPortal)
