import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useGoogleMap } from '@react-google-maps/api'
import Dropdown from '../../../components/Shared/Dropdown/Dropdown'
import Radio from '../../../components/Shared/Radio'
import Checkbox from '../../../components/Shared/Checkbox'
import MAP_TYPES from '../../../lib/constants/mapTypes'
import mapStyles from '../mapStyles'
import { zoomBreakPoint, defaultZoom, commonGoogleMapOptions } from '../constants'

const googleMapOptions = {
  ...commonGoogleMapOptions,
  styles: mapStyles
}

const MapControls = ({ className = '' }) => {
  const map = useGoogleMap()
  const [mapTypeId, setMapTypeId] = useState(MAP_TYPES.roadmap)
  const [zoom, setZoom] = useState(defaultZoom)
  const [tilt, setTilt] = useState(0)
  const [isTiltControlVisible, setIsTiltControlVisible] = useState(false)

  const handleTiltControlVisible = () => {
    const tiltState = (mapTypeId === MAP_TYPES.satellite) && (zoom >= zoomBreakPoint)
    const isRotateControlVisible = Boolean(tilt) && tiltState

    setIsTiltControlVisible(tiltState)
    map.setOptions({ ...googleMapOptions, rotateControl: isRotateControlVisible })

    if (!isRotateControlVisible) {
      setTilt(0)
      map.setTilt(0)
    }
  }

  const handleZoom = () => {
    setZoom(map.getZoom())
  }

  const handleChangeMapTypeId = event => {
    map.setMapTypeId(event.target.value)
    setMapTypeId(event.target.value)
  }

  const handleToggleTiltMode = () => {
    if (tilt) {
      setTilt(0)
      map.setTilt(0)
    } else {
      setTilt(45)
      map.setTilt(45)
    }
  }

  useEffect(() => {
    const listener = map.addListener('zoom_changed', handleZoom)
    return () => { listener.remove() }
  }, [])

  useEffect(() => {
    handleTiltControlVisible()
  }, [mapTypeId, zoom, tilt])

  return (
    <div className={cn('MapControls', className)}>
      {isTiltControlVisible && (
        <Checkbox
          name='tilt-mode'
          wrapperClassName='MapControls-tilt'
          onChange={handleToggleTiltMode}
          checked={Boolean(tilt)}
          label='Tilt'
        />)
      }

      <Dropdown
        id='map-type'
        className='Dropdown-mapType'
        titleClassName='Dropdown-title--mapType'
        contentClassName='Dropdown-content--mapType'
        title={<span>Map</span>}
      >
        <div className='MapControls-controls' role='radiogroup'>
          <div className='MapControls-control'>
            <Radio
              label='Street'
              onChange={handleChangeMapTypeId}
              value={MAP_TYPES.roadmap}
              name='map-type-switcher'
              checked={mapTypeId === MAP_TYPES.roadmap}
            />
          </div>
          <div className='MapControls-control'>
            <Radio
              label='Satellite'
              onChange={handleChangeMapTypeId}
              value={MAP_TYPES.satellite}
              name='map-type-switcher'
              checked={mapTypeId === MAP_TYPES.satellite}
            />
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

MapControls.propTypes = {
  className: PropTypes.string
}

export default MapControls
