import {
  mapPinAirportUrl,
  mapPinBeachUrl,
  mapPinBusUrl,
  mapPinBikeUrl,
  mapPinFarmUrl,
  mapPinGalleryUrl,
  mapPinGolfUrl,
  mapPinGroceryUrl,
  mapPinHelicopterUrl,
  mapPinMarketUrl,
  mapPinMuseumUrl,
  mapPinPoloUrl,
  mapPedalShareUrl,
  mapPinStrengthUrl,
  mapPinTennisUrl,
  mapPinTheaterUrl,
  mapPinTrainUrl,
  mapPinYogaUrl
} from '../../../components/Svg/Map/Pin'

const interestingPlacesPinUrls = {
  airport: mapPinAirportUrl,
  beach: mapPinBeachUrl,
  bus: mapPinBusUrl,
  cycling: mapPinBikeUrl,
  farm: mapPinFarmUrl,
  gallery: mapPinGalleryUrl,
  golf: mapPinGolfUrl,
  grocery: mapPinGroceryUrl,
  heliport: mapPinHelicopterUrl,
  market: mapPinMarketUrl,
  museum: mapPinMuseumUrl,
  pedalshare: mapPedalShareUrl,
  polo: mapPinPoloUrl,
  strength: mapPinStrengthUrl,
  tennis: mapPinTennisUrl,
  theater: mapPinTheaterUrl,
  train: mapPinTrainUrl,
  yoga: mapPinYogaUrl
}

export default interestingPlacesPinUrls
