import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Marker, InfoBox } from '@react-google-maps/api'
import { buildMarkerSvgBinary } from '../../../components/Shared/SearchResultsMap/utils'
import { useStores } from '../../Hooks'
import ListingCardPreview from '../ListingCardPreview'

const NearbyListing = ({ listing }) => {
  const { store: { mapStore } } = useStores()

  const handleSetActive = () => {
    mapStore.setActiveListingId(listing.id)
  }

  const handleSetInactive = () => {
    mapStore.setActiveListingId(null)
  }

  const handleOnSaveClick = item => e => {
    e.stopPropagation()
    e.preventDefault()
    mapStore.updateSaveStatus(item)
  }

  const isCardVisible = () => mapStore.hasActiveListing && mapStore.activeListingId === listing.id

  return (
    <Marker
      onClick={handleSetActive}
      position={{ lat: +listing.lat, lng: +listing.lon }}
      icon={{ url: buildMarkerSvgBinary({ listing }) }}
    >
      {isCardVisible()
        ? <InfoBox options={{
          boxStyle: { overflow: 'unset' },
          infoBoxClearance: new global.google.maps.Size(10, 10),
          closeBoxURL: '',
          pixelOffset: global.google && new global.google.maps.Size(-93, -287),
          enableEventPropagation: true
        }}>
          <ListingCardPreview
            listing={listing}
            handleOnSaveClick={handleOnSaveClick(listing)}
            handleSetInactive={handleSetInactive}
          />
        </InfoBox>
        : null
      }
    </Marker>
  )
}

NearbyListing.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lat: PropTypes.number,
    lon: PropTypes.number
  }).isRequired
}

export default observer(NearbyListing)
