import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useStores } from '../Hooks'
import cn from 'classnames'

const SliderIndicator = ({ classNames, totalCount }) => {
  const {store: {photoGalleryUIStateStore}} = useStores()

  return(
    <div className={cn('PhotoSlider-pageNumber', classNames)}>
      {photoGalleryUIStateStore.currentImageIndex + 1} / {totalCount}
    </div>
  )
}

SliderIndicator.propTypes = {
  classNames: PropTypes.string,
  totalCount: PropTypes.number.isRequired
}

export default observer(SliderIndicator)
