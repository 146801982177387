export const MIN_DISTANCE = 0.00001
export const MARKER_COLORS = {
  inactive: '#3e807f',
  active: '#f89911',
  viewed: '#B2B2B2',
  text: '#FFFFFF',
  viewedText: '#202020'
}

export const polygonBaseStyle = {
  fillColor: 'transparent',
  strokeColor: MARKER_COLORS.inactive,
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false
}

export const searchPolygonStyle = {
  fillOpacity: 0.2,
  strokeWeight: 2,
  strokeOpacity: 0.5,
  clickable: false
}
