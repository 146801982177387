import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { listingPropType } from '../../../lib/propTypes'
import { PlaceHolderHouseUrl } from '../../Svg'
import { DetailInfo, StatusInfo, PriceChange } from './Parts'
import { useReplaceBrokenImage } from '../../../shared_components/Hooks'

const CardPreview = ({ item, onSave }) => {
  const { src, source, imgRef } = useReplaceBrokenImage({
    src: item.featuredImageUrl.jpeg,
    source: { srcSet: item.featuredImageUrl.webp, type: 'image/webp' },
    fallback: { src: PlaceHolderHouseUrl, type: 'image/png' }
  })

  return (
    <div className='CardPreview'>
      <a href={item.links.self} className='CardPreview-link'>
        <picture>
          <source srcSet={source.srcSet} type={source.type}/>
          <img
            ref={imgRef}
            src={src}
            className='CardPreview-image'
            alt={item.townName}
          />
        </picture>
        <div className='CardPreview-status CardPreview-status--left'>
          <StatusInfo position='left' status={item.statusInfo }/>
        </div>
        <div className='CardPreview-content'>
          <div className='CardPreview-address'>
            {item.address || '(Undisclosed Address)'}
          </div>
          <div className='CardPreview-region'>
            { item.townName }
          </div>
          <div className='CardPreview-price'>
            <span className='CardPreview-priceNumber'>
              { item.price }
            </span>
            <PriceChange change={item.priceChange} />
          </div>
          <div className='CardPreview-detailInfo'>
            <DetailInfo item={item} />
            <div className='CardPreview-detailItem CardPreview-detailItem--pullRight'>
              <button onClick={onSave} className={cn('Button Button--saveLink', { isActive: item.saved })} />
            </div>
          </div>
          <div className='CardPreview-listingBy'>
            {item.listingBy}
          </div>
        </div>
      </a>
    </div>
  )
}

CardPreview.propTypes = {
  item: listingPropType.isRequired,
  onSave: PropTypes.func
}

export default CardPreview
