import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { compose, withHandlers, lifecycle, withProps } from 'recompose'
import VideoTourModal from '../../VideoTourModal'
import VideoTourButton from '../../VideoTourButton'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    videoTour: store.photoGalleryUIStateStore.videoTour,
    photoGalleryUIStateStore: store.photoGalleryUIStateStore
  })),
  withHandlers(({ photoGalleryUIStateStore }) => ({
    resetModal: () => () => { photoGalleryUIStateStore.toggleVideoTourModal(false) },
    openModal: () => () => { photoGalleryUIStateStore.toggleVideoTourModal(true) }
  })),
  lifecycle({
    componentDidMount() {
      document.addEventListener('turbolinks:before-cache', this.props.resetModal)
    },
    componentWillUnmount() {
      document.removeEventListener('turbolinks:before-cache', this.props.resetModal)
    }
  }),
  observer
)

const LightBoxVideoTourButton = ({ openModal, resetModal, videoTour, photoGalleryUIStateStore }) => (
  <Fragment>
    <VideoTourButton className='VideoTourButton--lightBox' onClick={openModal} />
    <VideoTourModal
      deactivateModal={resetModal}
      videoTour={videoTour}
      isVideoTourModalOpen={photoGalleryUIStateStore.isVideoTourModalOpen}
    />
  </Fragment>
)

LightBoxVideoTourButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  resetModal: PropTypes.func.isRequired,
  videoTour: PropTypes.shape({
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mediaType: PropTypes.string
  }),
  photoGalleryUIStateStore: PropTypes.shape({
    isVideoTourModalOpen: PropTypes.bool.isRequired
  })
}

export default enhance(LightBoxVideoTourButton)
