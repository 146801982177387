import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { compose, lifecycle, withHandlers, setPropTypes, defaultProps, withState } from 'recompose'
import ErrorVideo from '../ErrorVideo'

const enhance = compose(
  defaultProps({
    isInFocus: true
  }),
  setPropTypes({
    isInFocus: PropTypes.bool.isRequired,
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  withState('hasError', 'setError', false),
  withHandlers(() => {
    let player
    let playerRef

    return {
      setPlayerRef: () => ref => { playerRef = ref },
      setPlayer: ({ videoId, setError }) => () => {
        const Player = require('@vimeo/player').default
        player = new Player(playerRef, {
          id: videoId
        })
          .ready()
          .catch(() => { setError(true) })
      },
      pauseVideo: () => () => { if (player && player.pause) { player.pause() } }
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.setPlayer()
    },
    componentDidUpdate() {
      if(!this.props.isInFocus) { this.props.pauseVideo() }
    }
  })
)

const Vimeo = ({ className, setPlayerRef, hasError }) => (
  <div className={cn('VideoTour', className)}>
    { hasError && <ErrorVideo /> }
    <div ref={setPlayerRef} className='VideoTour-player' />
  </div>
)

Vimeo.propTypes = {
  className: PropTypes.string,
  setPlayerRef: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired
}

export default enhance(Vimeo)
