import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { MapStaticUrl } from '../../components/Svg'
import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { inject, observer } from 'mobx-react'
import MapModal from '../Map/MapModal'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    photoGalleryUIStateStore: store.photoGalleryUIStateStore,
    mapStore: store.mapStore,
    mapButtonRef: React.createRef()
  })),
  withHandlers(({ photoGalleryUIStateStore }) => ({
    resetModal: () => () => { photoGalleryUIStateStore.toggleMapModal(false) },
    openModal: () => () => { photoGalleryUIStateStore.toggleMapModal(true) }
  })),
  lifecycle({
    componentDidMount() {
      document.addEventListener('turbolinks:before-cache', this.props.resetModal)
    },
    componentWillUnmount() {
      document.removeEventListener('turbolinks:before-cache', this.props.resetModal)
    }
  }),
  observer
)

const LightBoxMapButton = ({
  openModal,
  resetModal,
  photoGalleryUIStateStore,
  mapStore,
  mapButtonRef
}) => (
  <Fragment>
    <button ref={mapButtonRef} className='MapButton MapButton--lightBox' onClick={openModal}>
      <div className='MapButton-container'>
        <div className='MapButton-content'>
          <img src={MapStaticUrl} className='MapButton-image' alt='Map'/>
        </div>
      </div>
    </button>
    <MapModal
      deactivateModal={resetModal}
      isMapModalOpen={photoGalleryUIStateStore.isMapModalOpen}
      photoGalleryUIStateStore={photoGalleryUIStateStore}
      mapStore={mapStore}
      mapButtonRef={mapButtonRef}
    />
  </Fragment>
)

LightBoxMapButton.propTypes = {
  mapButtonRef: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  resetModal: PropTypes.func.isRequired,
  mapInfo: PropTypes.any,
  viewOnGmapsInfo: PropTypes.object,
  interestingPlaces: PropTypes.any,
  interestingPlacesGroups: PropTypes.array,
  photoGalleryUIStateStore: PropTypes.shape({
    isMapModalOpen: PropTypes.bool.isRequired
  }),
  mapStore: PropTypes.object
}

export default enhance(LightBoxMapButton)
