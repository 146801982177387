import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'


const DetailInfo = ({ item }) => (
  [
    <div key={0} className='CardPreview-detailItem'>
      <span className='CardPreview-itemNumber'>
        { item.beds }
      </span>
      { ' ' }
      <span className='CardPreview-itemUnit'>
        BD
      </span>
    </div>,
    <div key={1} className='CardPreview-detailItem CardPreview-detailItem--noDelimiter'>
      <span className='CardPreview-itemNumber'>
        { item.baths }
      </span>
      { ' ' }
      <span className='CardPreview-itemUnit'>
        BA
      </span>
    </div>
  ]
)

const DetailInfoLand = ({ item }) => (
  <div className='CardPreview-detailItem CardPreview-detailItem--noDelimiter'>
    <span className='CardPreview-itemNumber'>
      { item.acreage }
    </span>
    { ' ' }
    <span className='CardPreview-itemUnit'>
      ACR
    </span>
  </div>
)

const propTypes = {
  item: PropTypes.shape({
    land: PropTypes.bool,
    baths: PropTypes.number,
    beds: PropTypes.number,
    acreage: PropTypes.number
  })
}

DetailInfo.propTypes = propTypes
DetailInfoLand.propTypes = propTypes

export default branch(
  ({ item }) => item.land,
  renderComponent(DetailInfoLand),
  renderComponent(DetailInfo)
)()
