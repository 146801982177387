import React from 'react'

const ErrorVideo = () => (
  <div className='ErrorVideo'>
    <div className='ErrorVideo-text'>
      <p className='Text Text--noSpace Text--slateColor'>Video is temporarily unavailable</p>
    </div>
  </div>
)

export default ErrorVideo
